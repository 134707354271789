<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="">
      <form
        class="form-horizontal"
        @submit.prevent="addingAnamnese"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <fieldset> 
          <animal-detail
            :show-detail="true"
            :animal-using="selectedAnimal"
            v-if="selectedAnimal !== null"
          />
          <div
            class="row"
            style="padding: 20px 10px;"
          >
            <div class="col-md-4">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="vaccine"
                  id="vaccine"
                >
                <label
                  class="form-check-label"
                  for="vaccine"
                >
                  Vacciné
                </label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="vermifuge"
                  id="vermifuge"
                >
                <label
                  class="form-check-label"
                  for="vermifuge"
                >
                  Vermifugé
                </label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="traitement"
                  id="traitement"
                >
                <label
                  class="form-check-label"
                  for="traitement"
                >
                  Animal sous traitement
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="dateOperation">Date de l'opération</label>
                <datepicker
                  id="dateOperation"
                  name="dateOperation"
                  input-class="form-control required"
                  :disabled-dates="disabledOperationDate"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  v-model="dateOperation"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="motif">Motif</label>
                <select
                  v-select="{placeholder: 'Selectionnez le motif de consultation'}"
                  class="form-control"
                  name="motif"
                  id="motif"
                  v-model="motif"
                >
                  <option />
                  <option
                    v-for="(m, i) in motifs"
                    :key="i"
                    :value="m.uid"
                  >
                    {{ m.libelle }}
                  </option>
                </select>
                <!-- <input
                    type="text"
                    class="form-control"
                    name="motif"
                    id="motif"
                    v-model="motif"
                  > -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="duration">Durée</label>
                <select
                  class="form-control"
                  id="duration"
                  v-select="{placeholder: 'Depuis quand le mal dure ?'}"
                  v-model="duration"
                >
                  <option value="" />
                  <option
                    v-for="(d, index) in durations"
                    :value="d"
                    :key="index"
                  >
                    {{ d }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="aliment">Alimentation</label>
                <select
                  id="aliment"
                  name="aliment"
                  class="form-control"
                  v-select="{placeholder: 'Selectionnez le type d\'alimentation de l\'animal'}"
                  v-model="aliment"
                  multiple
                >
                  <option value="" />
                  <option
                    v-for="(al, index) in aliments"
                    :value="al.uid"
                    :key="index"
                  >
                    {{ al.libelle }}
                  </option>
                </select>
              </div>
            </div>
    
            <div class="col-md-6">
              <div class="form-group">
                <label for="mode">Mode de vie</label>
                <select
                  name="mode"
                  id="mode"
                  class="form-control"
                  v-select="{placeholder: 'Selectionnez le mode de vie de l\'animal'}"
                  v-model="mode"
                >
                  <option value="" />
                  <option
                    v-for="(m, index) in modeVies"
                    :value="m.uid"
                    :key="index"
                  >
                    {{ m.libelle }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="symptome">Symptomes</label>
                <textarea
                  rows="3"
                  class="form-control"
                  name="symptome"
                  id="symptome"
                  v-model="symptome"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="antecedant">Antécedant médicaux</label>
                <textarea
                  rows="3"
                  class="form-control"
                  name="antecedant"
                  id="antecedant"
                  v-model="antecedant"
                />
              </div>
            </div>
          </div>
        </fieldset>
                
        <div class="row">
          <div class="col-12 text-right">
            <button
              class="btn btn-sm  btn-primary"
              type="submit"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
    
    <script>
    import { mapGetters, mapMutations } from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import navbar from '../../../components/navbar.vue'
    import animalDetail from '../../../components/identification/animal/detailInform.vue'
    import { UPDATE_ANAMNESE } from '../../../graphql/sanitaire'
    export default {
        components: {navbar, animalDetail, Datepicker},
        data(){
            return {
                navbarItems: [ 
                    { 
                        libelle: 'Sanitaire' 
                    },
                    { 
                        libelle: 'Anamneses',
                        route: 'anamnese'
                    },
                    {
                        libelle: 'Modification'
                    }
                ],
                pageIcon: 'la-hospital',
                pageTitle: 'Modification anamnese',
                pageDescription: 'Modification anamnese', 
                anamnese: null,
                durations: ['1 jour', '3 jours', '5 jours', 'Une Semaine', '2 Semaines', 'Plus de 2 semaines'],
                motif: null,
                duration: null,
                mode: null,
                aliment: [],
                vaccine: false,
                vermifuge: false,
                traitement: false,
                antecedant: null,
                symptome: null,
                dateOperation: new Date(),
                has_error: false,
                error_msg: null
            }
        },
        watch: {
            anamneses: {
                handler(){
                    this.load()
                },
                deep: true
            },
            anamnese: {
                handler(){
                    if(this.anamnese !== null){
                        console.log(this.anamnese)
                        this.setSelectedAnimal(this.anamnese.animal)
                        this.motif = this.anamnese.motif
                        this.vaccine = this.anamnese.vaccine
                        this.vermifuge = this.anamnese.vermifuge
                        this.traitement = this.anamnese.traitement
                        this.symptome = this.anamnese.symptome
                        this.duration = this.anamnese.duration
                        this.antecedant = this.anamnese.antecedant
                        this.mode = this.anamnese.modeVie
                        this.dateOperation =  this.anamnese.operationDate
                        this.aliment = JSON.parse(this.anamnese.alimentation)
                    }
                },
                deep: true
            }
        },
        mounted(){
            this.load()
        }, 
        methods: {
            ...mapMutations({
                done: 'DONE',
                setSelectedAnimal: 'identification/SET_SELECTED_ANIMAL'
            }),
            load(){
                if(this.$route.params.uid !== null){
                    this.anamnese =  this.anamneses.find(item => item.uid === this.$route.params.uid)
                }else{
                    this.anamnese =  null
                }
                
            },
            addingAnamnese(){
                let data = {
                    duration: this.duration,
                    animal: this.selectedAnimal,
                    antecedant: this.antecedant,
                    symptome: this.symptome,
                    traitement: this.traitement,
                    vaccine: this.vaccine,
                    vermifuge: this.vermifuge,
                    alimentation: JSON.stringify(this.aliment),
                    modeVie: this.mode,
                    motif: this.motif, 
                    operationDate: this.dateOperation
                }
                this.$apollo.mutate({
                  mutation: UPDATE_ANAMNESE,
                  variables: {
                      "anamnese": {
                          ...data
                      },
                      "uid": this.anamnese.uid
                  },
                  update: () => {
                      console.log('Update Anamnese success')
                  }
                }).then(() => {
                  this.$router.push({name: 'anamnese'})
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
            }
        },
        computed: {
            ...mapGetters({
                anamneses: 'sanitaire/anamneses',
                motifs: 'sanitaire/motifConsultations',
                aliments: 'identification/aliments',
                modeVies: 'sanitaire/modeVies',
                selectedAnimal: 'identification/selectedAnimal',
                animaux: 'identification/animaux',
            }),
            disabledOperationDate () {
                let animal = [...this.animaux].find(item => item.uid === this.selectedAnimal)
                if(animal !== null && animal !== undefined){
                    return {
                        to: new Date(animal.dateNaissance),
                        from: new Date()
                    }
                }
                return {
                    to: new Date(),
                    from: new Date()
                }
            },
        }
    }
    </script>
    
    <style>
    
    </style>